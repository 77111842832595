<template lang="pug">
v-dialog(v-model="dialog", width="500", @keydown.esc="dialog = false")
  v-card(v-if="program")
    v-toolbar(flat, dense)
      .subtitle-2 Edit program
    v-card-text
      v-form(@submit.prevent="submit")
        v-text-field(label="Name", v-model.trim="program.name")
        v-subheader Locations {{ program.bizs.length }}
        simple-table(v-if="selected?.length")
          tbody
            tr(v-for="(item, index) in selected", :key="index")
              td {{ item.name }}
              td.text-right
                v-btn(icon, small, @click="removeBiz(item._id)")
                  v-icon(small, color="error") mdi-delete
        BizSelector(@select="selectBiz", :exclude="program.bizs")
        v-btn.mt-2(block, color="secondary", type="submit", :loading="loading") Save
        .pl-2.red--text(
          v-for="(error, index) in errors",
          :key="index + 'index'"
        ) {{ error }}
</template>

<script>
import { EventBus } from "@/event-bus.js";
import { mapActions } from "vuex";

export default {
  data() {
    return {
      program: null,
      dialog: false,
      errors: [],
      loading: false,
      defaultBizs: [],
    };
  },
  computed: {
    selected() {
      return this.program?.bizs
        ?.map((o) => {
          const found = this.defaultBizs.find((b) => b._id == o);
          return { _id: o, name: found?.name || o, address: found?.address };
        })
        ?.sort((a, b) => a.name.localeCompare(b.name));
    },
  },
  mounted() {
    EventBus.$on("edit-member-program", this.open);
  },
  destroyed() {
    EventBus.$off("edit-member-program", this.open);
  },
  methods: {
    ...mapActions(["updateMemberProgram", "addMemberProgram"]),
    open(val) {
      if (!val) {
        this.program = { name: "", bizs: [] };
      } else {
        this.program = JSON.parse(JSON.stringify(val));
      }
      this.errors = [];
      this.dialog = true;
      this.loadDefault();
    },
    async loadDefault() {
      if (!this.program || !this.program.bizs) return;
      const params = {
        criteria: { _id: { $in: this.program.bizs } },
        select: "name",
      };
      this.defaultBizs = await this.$api.biz.list(params);
      if (!this.program.name && this.defaultBizs?.length) {
        this.program.name = this.defaultBizs[0].name;
      }
    },
    removeBiz(id) {
      if (!this.program || !this.program.bizs) return;
      const index = this.program.bizs.indexOf(id);
      if (index >= 0) this.program.bizs.splice(index, 1);
    },
    selectBiz(biz) {
      if (!this.program || !biz) return;
      if (!this.program.bizs) this.program.bizs = [biz._id];
      if (this.program.bizs.indexOf(biz._id) < 0)
        this.program.bizs.push(biz._id);
      this.defaultBizs.push(biz);
      if (!this.program.name) {
        this.program.name = biz.name;
      }
    },
    async submit() {
      this.errors = [];
      if (!this.program._id) {
        const result = await this.$api.memberProgram.create(this.program);
        this.addMemberProgram(result);
        this.loading = false;
        this.dialog = false;
      } else {
        const params = {
          criteria: { _id: this.program._id },
          action: { $set: this.program },
        };
        const result = await this.$api.memberProgram.update(params);
        this.updateMemberProgram(result);
        this.loading = false;
        this.dialog = false;
      }
    },
  },
};
</script>