<template lang="pug">
div
  TaxExempt
  CancelOrder
  ChargeBack
  AdjustPrice
</template>
<script>
import AdjustPrice from "./AdjustPrice";
import CancelOrder from "./CancelOrder";
import ChargeBack from "./ChargeBack/index";
import TaxExempt from "./TaxExempt";

export default {
  components: {
    TaxExempt,
    CancelOrder,
    ChargeBack,
    AdjustPrice,
  },
};
</script>