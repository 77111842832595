<template lang="pug">
v-dialog(v-model="dialog", width="900", scrollable)
  v-form(@submit.prevent="submit")
    v-card(v-if="announcement")
      v-toolbar(dense, flat)
        .subtitle-2 {{ title }} Announcement
      v-card-text
        v-row
          v-col(cols="6")
            v-text-field(label="Title", v-model="announcement.title")
          v-col(cols="6")
            v-text-field(label="Author", v-model="announcement.author_name")
        v-row
          v-col(cols="10")
            v-text-field(label="URL", v-model="announcement.url")
          v-col(cols="2")
            v-btn(@click="sluggify", text) Auto
        v-row
          v-col(cols="6")
            v-select(
              v-model="announcement.readers",
              :items="reader_items",
              multiple
            )
          v-col(cols="6")
            v-switch(
              v-model="announcement.required_read",
              label="Required Read",
              color="secondary"
            )
        v-row.mb-3
          v-col(cols="6")
            DateFieldPicker(label="From", v-model="start")
          v-col(cols="6")
            DateFieldPicker(label="To", v-model="end")
        v-md-editor.mb-2(v-model="announcement.summary")
        v-md-editor(v-model="announcement.content")
      v-card-actions
        v-btn(block, color="secondary", type="submit", :loading="loading") Save
</template>

<script>
import moment from "moment-timezone";
import { mapActions, mapGetters } from "vuex";

export default {
  name: "AnnouncementForm",
  data() {
    return {
      announcement: null,
      dialog: false,
      loading: false,
      start: moment().toISOString().substring(0, 10),
      end: moment().toISOString().substring(0, 10),
      reader_items: [
        { text: "User", value: "user" },
        { text: "Biz Owner", value: "biz_owner" },
        { text: "Driver", value: "driver" },
      ],
    };
  },
  computed: {
    ...mapGetters(["biz"]),
    title() {
      if (this.announcement?._id) return "Edit an";
      return "Create a New";
    },
  },
  methods: {
    ...mapActions(["addAnnouncement", "updateAnnouncement"]),
    open(data) {
      const user = this.$auth.user();
      if (!data) {
        this.announcement = {
          author_id: user._id,
          author_name: "",
          title: "",
          summary: "",
          url: "",
          content: "",
          readers: [],
          email: false,
          required_read: false,
        };
      } else {
        this.announcement = JSON.parse(JSON.stringify(data));
      }
      if (!this.announcement.url) this.sluggify();
      this.start = moment(this.announcement.start)
        .toISOString()
        .substring(0, 10);
      this.end = moment(this.announcement.end).toISOString().substring(0, 10);
      this.dialog = true;
    },
    sluggify() {
      const text = this.announcement.title
        .toLowerCase()
        .replace(/ /g, "-")
        .replace(/[^\w-]+/g, "");
      this.announcement.url = text.substring(0, 30) + "-" + this.start;
    },
    validate() {
      if (!this.announcement.title) throw new Error("Title is required.");
      if (!this.announcement.url) throw new Error("URL is required.");
      if (!this.announcement.content) throw new Error("Content is required.");
      this.announcement.start = moment(this.start).valueOf();
      this.announcement.end = moment(this.end).valueOf();
    },
    async submit() {
      const user = this.$auth.user();
      this.loading = true;
      try {
        this.validate();
        const params = {
          criteria: { _id: this.announcement._id },
          action: {
            $set: { author_id: user._id, ...this.announcement },
          },
        };
        if (this.announcement._id) {
          const result = await this.$api.announcement.update(params);
          this.updateAnnouncement(result);
        } else {
          const result = await this.$api.announcement.create(this.announcement);
          this.addAnnouncement(result);
        }
        this.dialog = false;
      } catch (e) {
        this.$toast.error(e.response?.data || e.message);
      }
      this.loading = false;
    },
  },
};
</script>
