<template lang="pug">
v-row(wrap, justify="center")
  simple-table.ma-5(v-if="orders")
    tbody
      tr(
        v-if="byProxies",
        v-for="(item, index) in byProxies",
        :key="index + 'proxy'"
      )
        td {{ item.name }}
        td {{ item.value | currency }}
        td {{ item.tip | currency }} ({{ ((item.tip / item.value) * 100) | number(1) }}%)
        td {{ item.count }}
      tr(
        v-if="byTypes",
        v-for="(item, index) in byTypes",
        :key="index + 'value'"
      )
        td.text-capitalize {{ item.name }}
        td {{ item.value | currency }}
        td {{ item.tip | currency }} ({{ ((item.tip / item.value) * 100) | number(1) }}%)
        td {{ item.count }}
      tr(v-if="catering")
        td {{ catering.name }}
        td {{ catering.value | currency }}
        td {{ catering.tip | currency }} ({{ ((catering.tip / catering.value) * 100) | number(1) }}%)
        td {{ catering.count }}
      tr
        td Total
        td {{ totalValue | currency }}
        td {{ totalTip | currency }}
        td {{ orders.length }}
</template>

<script>
import _ from "underscore";
import { mapGetters } from "vuex";

export default {
  computed: {
    ...mapGetters(["orders"]),
    totalTip() {
      return this.getTip(this.orders);
    },
    totalValue() {
      return this.getValue(this.orders);
    },
    byTypes() {
      return this.getMappedGroups("type");
    },
    byProxies() {
      return this.getMappedGroups("proxy");
    },
    catering() {
      const list = this.orders.filter((o) => o.catering);
      if (!list.length) return null;
      return {
        name: "Catering",
        count: list.length,
        tip: this.getTip(list),
        value: this.getValue(list),
      };
    },
  },
  methods: {
    getMappedGroups(name) {
      if (!this.orders) return [];
      const getTip = this.getTip;
      const getValue = this.getValue;
      return _.chain(this.orders)
        .groupBy(name)
        .map((list, name) => {
          const tip = getTip(list);
          const value = getValue(list);
          return { name, count: list.length, tip, value };
        })
        .sortBy("name")
        .value();
    },
    getTip(list) {
      return list.reduce((a, b) => {
        return a + b.payment.tip;
      }, 0);
    },
    getValue(list) {
      return list.reduce((a, b) => {
        return a + b.payment.total;
      }, 0);
    },
  },
};
</script>
