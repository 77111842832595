<template lang="pug">
div
  v-text-field(v-model="searchText", label="Search Program", clearable)
  v-data-table(
    :items="memberPrograms",
    :headers="headers",
    :search="searchText",
    sort-by="name",
    dense,
    @click:row="edit",
    role="button"
  )
    template(v-slot:item.created="{ item }")
      span {{ item.created | date }}
    template(v-slot:item.action="{ item }")
      v-menu(offset-y)
        template(v-slot:activator="{ on }")
          v-btn(icon, small, v-on.stop="on")
            v-icon(color="secondary") mdi-dots-horizontal
        v-list(dense)
          Merge(:program="item")
          Populate(:program="item")
          DeleteOne(:program="item")
  span(v-if="duplicate") Duplicate Biz: {{ duplicate }}
</template>

<script>
import { EventBus } from "@/event-bus.js";
import _ from "underscore";
import { mapGetters } from "vuex";
import DeleteOne from "./DeleteOne";
import Merge from "./Merge";
import Populate from "./Populate";

export default {
  components: { Populate, Merge, DeleteOne },
  data() {
    return {
      searchText: "",
      headers: [
        { text: "Name", value: "name" },
        { text: "ID", value: "_id" },
        { text: "Bizs", value: "bizs.length", filterable: false },
        { text: "#Members", value: "count", filterable: false },
        { text: "Created", value: "created", align: "end", filterable: false },
        { text: "Action", value: "action", align: "end", filterable: false },
      ],
    };
  },
  computed: {
    ...mapGetters(["memberPrograms"]),
    duplicate() {
      let list = [];
      _.chain(this.memberPrograms)
        .pluck("bizs")
        .flatten()
        .sortBy()
        .countBy()
        .each((value, key) => {
          if (value > 1) list.push(key);
        });
      return list?.join(", ");
    },
  },
  methods: {
    edit(item) {
      EventBus.$emit("edit-member-program", item);
    },
  },
};
</script>