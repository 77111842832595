<template lang="pug">
v-card.center-align(max-width="400", flat)
  v-toolbar(flat, dense)
    .subtitle-2 Search Online Orders
  v-card-text
    BizSelector(@select="selectBiz")
    DaySelector(@select="selectDate")
  v-card-actions
    v-btn(
      @click="load()",
      color="secondary",
      :loading="loading",
      x-small,
      outlined
    ) Load All
    v-spacer 
    v-btn(
      @click="loadByBiz()",
      color="secondary",
      :disabled="!biz",
      :loading="loading"
    ) Search
</template>

<script>
import moment from "moment-timezone";
import { mapActions } from "vuex";

export default {
  data: () => ({
    biz: null,
    date: "",
    loading: false,
  }),
  mounted() {
    this.setOrders();
  },
  methods: {
    ...mapActions(["setOrders", "updateOrder"]),
    selectBiz(biz) {
      this.biz = biz;
    },
    selectDate(date) {
      this.date = date;
    },
    async loadByBiz() {
      if (!this.biz) return;
      this.load(this.biz._id);
    },
    async load(bizId) {
      if (!this.date) return;
      this.loading = true;
      try {
        const begin = moment(this.date).startOf("day").valueOf();
        const end = moment(this.date).endOf("day").valueOf();
        let criteria = { "orderer.type": 0 };
        if (bizId)
          criteria = {
            "seller.id": bizId,
            ...criteria,
            needed: { $gte: begin, $lte: end },
          };
        else {
          criteria = { ...criteria, created: { $gte: begin, $lte: end } };
        }
        const select =
          "seller orderer catering needed orderNumber status payment proxy type deliv";
        const params = { criteria, select };
        const result = await this.$api.order.list(params);
        this.setOrders(result);
      } catch (e) {
        this.$toast.error(e.message);
      }
      this.loading = false;
    },
  },
};
</script>

<style lang="sass" scoped>
.center-align
  margin-left: auto
  margin-right: auto
  max-width: 400px
</style>