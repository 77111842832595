import POSActivities from './POSClients/Activities/index.vue'
import CardPointe from './POSClients/CardPointe/index.vue'
import Chowly from './POSClients/Chowly/index.vue'
import Clients from './POSClients/Clients/index.vue'
import POSFee from './POSClients/index.vue'
import InvoicePlans from './POSClients/Plans/index.vue'
import ServiceItems from './POSClients/ServiceItems/index.vue'
import SevenShifts from './POSClients/SevenShifts/index.vue'
import ServiceSubscriptions from './POSClients/Subscriptions/index.vue'

import GrowthBizAccount from './Growth/BizAccount/index.vue'
import GrowthConsolidate from './Growth/Consolidate/index.vue'
import GrowthDelivOrder from './Growth/DelivOrder/index.vue'
import GrowthGiftCard from './Growth/GiftCard/index.vue'
import Growth from './Growth/index.vue'
import GrowthMerchant from './Growth/Merchant/index.vue'
import GrowthOrderMessaging from './Growth/OrderMessaging/index.vue'
import GrowthPOS from './Growth/POS/index.vue'
import GrowthPromotion from './Growth/Promotion/index.vue'

import Access from './Access/index.vue'
import Press from '/libs/components/Company/Press/Internal'

import Announcement from './Tools/Announcement/index.vue'
import EmailLog from './Tools/EmailLog/index.vue'
import EmailSample from './Tools/EmailSample/index.vue'
import Tools from './Tools/index.vue'
import Logs from './Tools/Logs/index.vue'
import Podcast from './Tools/Podcast/index.vue'
import SuccessStory from './Tools/SuccessStory/index.vue'

import invoice from './Invoice/route'

export default [
  {
    path: '/pos',
    component: POSFee,
    meta: { auth: 'corp' },
    children: [
      { path: '', redirect: 'clients' },
      { path: 'clients', component: Clients },
      { path: 'chowly', component: Chowly },
      { path: '7shifts', component: SevenShifts },
      { path: 'card_pointe', component: CardPointe },
      { path: 'service_items', component: ServiceItems },
      { path: 'plans', component: InvoicePlans },
      { path: 'subscriptions', component: ServiceSubscriptions },
      { path: 'activities', component: POSActivities },
    ]
  },
  ...invoice,
  {
    path: '/growth',
    component: Growth,
    meta: { auth: 'corp' },
    children: [
      { path: '', redirect: 'pos' },
      { path: 'pos', component: GrowthPOS },
      { path: 'giftcard', component: GrowthGiftCard },
      { path: 'delivorder', component: GrowthDelivOrder },
      { path: 'bizaccount', component: GrowthBizAccount },
      { path: 'promotion', component: GrowthPromotion },
      { path: 'messaging', component: GrowthOrderMessaging },
      { path: 'consolidate', component: GrowthConsolidate },
      { path: 'merchant', component: GrowthMerchant }
    ]
  },
  { path: '/press', component: Press, meta: { auth: 'corp' } },
  { path: '/corp/access', component: Access, meta: { auth: 'corp' } },
  {
    path: '/tools',
    component: Tools,
    meta: { auth: 'corp' },
    children: [
      { path: 'email_samples', component: EmailSample },
      { path: 'email_logs', component: EmailLog },
      { path: 'announcement', component: Announcement },
      { path: 'success_story', component: SuccessStory },
      { path: 'podcast', component: Podcast },
      { path: 'logs', component: Logs }
    ]
  }
]