<template lang="pug">
div
  DaySelector(@select="load")
  Table.my-2(:items="items")
  PITable.my-2(:items="piItems")
  DayTable.my-2(:items="dayItems")
  .d-flex.flex-row
    CreateByDay.mr-3(@created="load", :date="selectedDate")
    CreateByMonth.mr-3(@created="load", :date="selectedDate")
    //- UpdateStripePI(:selectedDate="selectedDate")
</template>

<script>
import moment from "moment-timezone";
import CreateByDay from "./CreateByDay";
import CreateByMonth from "./CreateByMonth";
import DayTable from "./DayTable";
import PITable from "./PITable";
import Table from "./Table";
import UpdateStripePI from "./UpdateStripePI";

export default {
  components: {
    Table,
    PITable,
    DayTable,
    UpdateStripePI,
    CreateByDay,
    CreateByMonth,
  },
  data() {
    return {
      items: [],
      selectedDate: null,
      dayItems: [],
      piItems: [],
    };
  },
  methods: {
    async load(date) {
      if (date) this.selectedDate = date;
      if (!this.selectedDate) return;
      const begin = moment(this.selectedDate).startOf("day").valueOf();
      const end = moment(this.selectedDate).endOf("day").valueOf();
      const params = {
        criteria: { created: { $gte: begin, $lt: end } },
        limit: 3000,
      };
      this.items = await this.$api.transaction.stripe.list(params);
      this.listByDay();
      this.loadPI();
    },
    async loadPI() {
      const begin = moment(this.selectedDate).startOf("day").unix();
      const end = moment(this.selectedDate).endOf("day").unix();
      const params = {
        criteria: { created: { $gte: begin, $lt: end } },
        limit: 3000,
      };
      this.piItems = await this.$api.stripe.paymentIntent.list(params);
    },
    async listByDay() {
      const date = parseInt(moment(this.selectedDate).format("YYYYMMDD"));
      this.dayItems = await this.$api.transaction.stripe.custom("listByDay", {
        criteria: { date },
      });
    },
  },
};
</script>