<template lang="pug">
.s-vstack
  Summary
  List(:orders="created", title="New Orders")
  List(:orders="processed", title="Processed Orders")
  List(:orders="canceled", title="Canceled Orders")
  SummaryByBiz
  DelivOrder
  Compensate
  ChangeToDelivery
  UpdateAddress
</template>

<script>
import DelivOrder from "@/components/Sales/DelivOrder/index.vue";
import { mapGetters } from "vuex";
import ChangeToDelivery from "./ChangeToDelivery/index";
import Compensate from "./Compensate";
import List from "./List/index.vue";
import Summary from "./Summary";
import SummaryByBiz from "./SummaryByBiz";
import UpdateAddress from "./UpdateAddress/index";

export default {
  components: {
    Summary,
    SummaryByBiz,
    List,
    DelivOrder,
    Compensate,
    ChangeToDelivery,
    UpdateAddress,
  },
  computed: {
    ...mapGetters(["orders"]),
    created() {
      return this.orders
        ?.filter((o) => o.status == 0)
        ?.toSorted((a, b) => b.created - a.created);
    },
    processed() {
      return this.orders
        ?.filter((o) => o.status > 0)
        ?.toSorted((a, b) => b.created - a.created);
    },
    canceled() {
      return this.orders
        ?.filter((o) => o.status < 0)
        ?.toSorted((a, b) => b.created - a.created);
    },
  },
};
</script>
