<template lang="pug">
Page(title="Tools", :items="items")
</template>

<script>
export default {
  data() {
    return {
      items: [
        { title: "Email Samples", url: "/tools/email_samples" },
        { title: "Email Logs", url: "/tools/email_logs" },
        { title: "Announcements", url: "/tools/announcement" },
        { title: "Success Stories", url: "/tools/success_story" },
        { title: "Podcasts", url: "/tools/podcast" },
        { title: "Logs", url: "/tools/logs" },
      ],
    };
  },
};
</script>
