<template lang="pug">
.s-vstack
  .d-flex.flex-row.align-center
    .subtitle-2 Member Programs
    v-spacer
    v-btn.text-capitalize(
      @click="create()",
      outlined,
      small,
      depressed,
      color="grey darken-2"
    ) Add
      v-icon(right, small) mdi-plus
  Table
  CountByProgram
  CountByBiz
  Form
</template>

<script>
import { EventBus } from "@/event-bus.js";
import { mapActions } from "vuex";
import CountByBiz from "./CountByBiz";
import CountByProgram from "./CountByProgram";
import Form from "./Form";
import Table from "./Table/index";

export default {
  components: { Table, CountByBiz, CountByProgram, Form },
  mounted() {
    this.load();
  },
  methods: {
    ...mapActions(["setMemberPrograms"]),
    async load() {
      const params = { criteria: {} };
      const result = await this.$api.memberProgram.list(params);
      this.setMemberPrograms(result);
    },
    create() {
      EventBus.$emit("edit-member-program");
    },
  },
};
</script>